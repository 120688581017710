import Image from "components/Image"
import CallToAction from "components/CallToAction"
import getStoryblokLink from "utilities/getStoryblokLink"
import richText from "utilities/richText"

function Card({ title, content, cta_label, cta_link, photo }) {
  return (
    <div className="bg-white py-10 px-6 xl:px-12 group last-odd:lg:col-span-2">
      <div className=" bg-white flex sm:gap-4 xl:gap-0 relative items-start">
        <div className="basis-1/2 md:basis-9/12 lg:basis-1/2 group-last-odd:md:basis-9/12 group-last-odd:lg:basis-auto group-last-odd:lg:flex group-last-odd:gap-8 group-last-odd:lg:pr-56">
          <h3 className="text-black pb-4">{title}</h3>
          <div>
            <div className="w-[180px] sm:w-auto sm:max-w-none xl:max-w-xs group-last-odd:max-w-none">
              {richText(content)}
            </div>
          </div>
        </div>
        <Image
          loader={undefined}
          src={`/assets/pets/${photo}.png`}
          alt={photo.replaceAll("-", " ")}
          width={200}
          height={350}
          className="object-contain basis-1/2 md:basis-3/12 lg:basis-1/2 -mr-24 xl:-mr-12 xl:absolute xl:right-0 xl:-top-3 group-last-odd:lg:absolute group-last-odd:md:basis-3/12 group-last-odd:lg:basis-auto group-last-odd:lg:-mr-0 group-last-odd:lg:h-auto group-last-odd:lg:right-0 group-last-odd:lg:-top-20"
        />
      </div>
      <CallToAction
        href={getStoryblokLink(cta_link)}
        className="mt-4 table mx-auto lg:mx-0 text-s3 basis-full"
        style="secondary"
      >
        {cta_label}
      </CallToAction>
    </div>
  )
}

export default function ServiceCardSection({ blok }) {
  return (
    <section className="bg-grayPawprint overflow-hidden pt-12 pb-20 px-6">
      <h2 className="text-white text-center pb-12 sm:pb-20 uppercase">{blok.title}</h2>
      <div className="grid lg:grid-cols-2 gap-12 lg:gap-x-20 lg:gap-y-12 max-w-screen-xl mx-auto">
        {blok.cards.map((card, idx) => (
          <Card {...card} key={idx} />
        ))}
      </div>
    </section>
  )
}
